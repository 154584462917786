import React, { FC } from 'react';
import { Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useStore from '../../../hooks/useStore';
import ContentStore from '../../../stores/ContentStore';
import ModalStore from '../../../stores/ModalStore';
import MoveProductToSiblingaccountModal from './modals/MoveProductToSiblingaccountModal';
import { IdentifierStatus, ProductInfo, UserRole } from '../../../swagger/api/index';
import { ReactComponent as Close } from '../../../layout/icons/close.svg';
import { ReactComponent as ConvertToTollingBoxIcon } from '../../../layout/icons/convert-bizz-to-tolling-box-icon.svg';
import { ReactComponent as Folder } from '../../../layout/icons/folder.svg';
import ConfirmCloseProductModal from '../product-card/modals/ConfirmCloseProductModal';
import AccountStore from '../../../stores/AccountStore';
import LoadingSpinner from '../../../common-components/LoadingSpinner';
import RouteUrlPaths from '../../../RouteUrlPaths';
import { isBizz } from '../../../utils/productTypeUtilities';
import OrderStore from '../../../stores/OrderStore';

const StyledMenu = styled(Menu)`
  border-radius: 8px;
`;

const StyledMenuItem = styled(Menu.Item)`
  margin: 3px 8px;
  border-radius: 6px;
  padding-right: 75px;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
`;

interface ProductMenuProps {
  product: ProductInfo;
  isMoveToSubaccountLoading: boolean;
}

const ProductMenu: FC<ProductMenuProps> = (props) => {
  const { product, isMoveToSubaccountLoading, children } = props;
  const modalStore: ModalStore = useStore(ModalStore);
  const contentStore: ContentStore = useStore(ContentStore);
  const accountStore: AccountStore = useStore(AccountStore);
  const orderStore: OrderStore = useStore(OrderStore);
  const { productCardStore } = contentStore.rootStore.productStore;
  const navigate = useNavigate();

  const deleteProductIsNotAllowed =
    contentStore.rootStore.userStore.person?.role === UserRole.ProductAccess;

  const showMoveTosubaccount = () =>
    isBizz(product.productType) &&
    product.identifierStatus !== IdentifierStatus.Blocked &&
    accountStore.siblingaccounts.length > 0;

  const moveToSubAccountMenuItem = showMoveTosubaccount() && (
    <StyledMenuItem
      key="0"
      icon={<Folder />}
      onClick={() =>
        modalStore.openModal(
          <MoveProductToSiblingaccountModal closeModal={modalStore.closeModal} product={product} />
        )
      }
    >
      {contentStore.cmsStore.getContent('MoveToSubAccount')?.content}
    </StyledMenuItem>
  );

  const convertToTollingBoxMenuItem = (
    <StyledMenuItem
      key="1"
      icon={<ConvertToTollingBoxIcon />}
      onClick={() => {
        productCardStore.closeModal();
        orderStore.orderInfoStore.resetOrderFlow();
        orderStore.orderInfoStore.createNewConvertBizzList([product]);
        orderStore.orderInfoStore.setCurrentOrderCount(orderStore.orderInfoStore.products.length);
        navigate(`/${RouteUrlPaths.ConvertToTollingBox}`, {
          state: { showSelectBizzesToConvert: false, bizzId: product.identifierId }
        });
      }}
    >
      {contentStore.cmsStore.getContent('ConvertToTollingBoxBurgerMenuItem')?.content}
    </StyledMenuItem>
  );

  const closeProductMenuItem = (
    <StyledMenuItem
      key="2"
      icon={<Close />}
      disabled={deleteProductIsNotAllowed}
      onClick={() =>
        modalStore.openModal(
          <ConfirmCloseProductModal
            contentStore={contentStore}
            productInfo={product}
            modalStore={modalStore}
            productCardStore={productCardStore}
          />
        )
      }
    >
      {contentStore.cmsStore.getContent('CloseProduct')?.content}
    </StyledMenuItem>
  );

  const productMenu = (
    <StyledMenu>
      {isMoveToSubaccountLoading ? <LoadingSpinner size="sm" /> : moveToSubAccountMenuItem}
      {isBizz(product.productType) &&
        !product.convertingToTollingBoxId &&
        convertToTollingBoxMenuItem}
      {closeProductMenuItem}
    </StyledMenu>
  );

  return (
    <Dropdown overlay={productMenu} trigger={['click']} arrow={false}>
      {children}
    </Dropdown>
  );
};

export default observer(ProductMenu);

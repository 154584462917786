import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CustomModal from '../../../../common-components/CustomModal';
import ProductItemsStore from '../../../../stores/product-stores/ProductItemsStore';
import { ProductInfo, IdentifierStatus } from '../../../../swagger/api/index';
import ContentStore from '../../../../stores/ContentStore';
import useStore from '../../../../hooks/useStore';

interface ActiveInactiveModalProps {
  closeModal: () => void;
  productInfo: ProductInfo;
  productItemsStore: ProductItemsStore;
}

const activeInactiveModal: FC<ActiveInactiveModalProps> = (props) => {
  const { closeModal, productInfo, productItemsStore } = props;
  const contentStore: ContentStore = useStore(ContentStore);
  const [isLoading, setIsloading] = useState(false);

  const header =
    productInfo.identifierStatus === IdentifierStatus.Active
      ? contentStore.cmsStore.getContent('DeactivateProduct')?.content!
      : contentStore.cmsStore.getContent('ActivateProduct')?.content!;

  const content =
    productInfo.identifierStatus === IdentifierStatus.Active
      ? contentStore.cmsStore.getContent('DeactivateProduct')?.subText!
      : contentStore.cmsStore.getContent('ActivateProduct')?.subText!;

  const primaryButtonText = contentStore.cmsStore.getContent('Correct')?.content;

  const greenLinkText = contentStore.cmsStore.getContent('Undo')?.content;

  return (
    <CustomModal
      header={header}
      contentText={content}
      primaryButtonText={primaryButtonText}
      isLoading={isLoading}
      closable
      greenLinkText={greenLinkText}
      onGreenLinkClick={() => {
        closeModal();
      }}
      onPrimaryButtonClick={() => {
        setIsloading(true);
        const promise = productItemsStore.toggleBizzStatus(productInfo.identifierId!);
        promise.finally(() => {
          setIsloading(false);
          closeModal();
        });
      }}
    />
  );
};

export default observer(activeInactiveModal);

/* eslint-disable import/no-cycle */
import { createContext } from 'react';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import ProductStore from './ProductStore';
import ModalStore from './ModalStore';
import AccountStore from './AccountStore';
import LoadingStore from './LoadingStore';
import FileHandlerStore from './FileHandlerStore';
import TransactionsStore from './TransactionsStore';
import FilterStore from './FilterStores';
import ErrorStore from './Errorstore';
import PaymentStore from './PaymentStore';
import AuthenticationStore from './AuthenticationStore';
import ContentStore from './ContentStore';
import OrderStore from './OrderStore';
import NotificationStore from './NotificationStore';
import { serviceOptions } from '../swagger/api/index';
import UserStore from './UserStore';
import SubAccountStore from './adminstration-stores/SubAccountStore';
import FrontPageStore from './FrontPageStore';
import AdministrationStore from './AdministrationStore';
import SignUpStore from './SignUpStore';
import ManageUserAccessStore from './ManageUserAccessStore';
import { Locale } from './content-stores/CMSStore';
import PaymentCardStore from './PaymentCardStore';
import MenuStore from './MenuStore';
import AddressSelectorStore from './AddressSelectorStore';
import CreatePrivateUserStore from './CreatePrivateUserStore';
import CustomerServiceStore from './CustomerServiceStore';

export class RootStore {
  private axiosInstance?: AxiosInstance;

  productStore: ProductStore;

  modalStore: ModalStore;

  accountStore: AccountStore;

  loadingStore: LoadingStore;

  fileHandlerStore: FileHandlerStore;

  transactionsStore: TransactionsStore;

  errorStore: ErrorStore;

  paymentStore: PaymentStore;

  filterStore: FilterStore;

  contentStore: ContentStore;

  userStore: UserStore;

  orderStore: OrderStore;

  authenticationStore: AuthenticationStore;

  notificationStore: NotificationStore;

  signUpStore: SignUpStore;

  frontPageStore: FrontPageStore;

  administrationStore: AdministrationStore;

  subAccountStore: SubAccountStore;

  manageUserAccessStore: ManageUserAccessStore;

  paymentCardStore: PaymentCardStore;

  menuStore: MenuStore;

  addressSelectorStore: AddressSelectorStore;

  createPrivateUserStore: CreatePrivateUserStore;

  customerServiceStore: CustomerServiceStore;

  constructor() {
    this.contentStore = new ContentStore(this);
    this.accountStore = new AccountStore(this);
    this.userStore = new UserStore(this);
    this.manageUserAccessStore = new ManageUserAccessStore(this);
    this.orderStore = new OrderStore(this);
    this.productStore = new ProductStore(this);
    this.modalStore = new ModalStore(this);
    this.loadingStore = new LoadingStore(this);
    this.fileHandlerStore = new FileHandlerStore(this);
    this.transactionsStore = new TransactionsStore(this);
    this.errorStore = new ErrorStore(this);
    this.paymentStore = new PaymentStore(this);
    this.transactionsStore = new TransactionsStore(this);
    this.authenticationStore = new AuthenticationStore(this);
    this.filterStore = new FilterStore(this);
    this.notificationStore = new NotificationStore(this);
    this.signUpStore = new SignUpStore(this);
    this.frontPageStore = new FrontPageStore(this);
    this.administrationStore = new AdministrationStore(this);
    this.subAccountStore = new SubAccountStore(this);
    this.paymentCardStore = new PaymentCardStore(this);
    this.menuStore = new MenuStore(this);
    this.addressSelectorStore = new AddressSelectorStore(this);
    this.createPrivateUserStore = new CreatePrivateUserStore(this);
    this.customerServiceStore = new CustomerServiceStore(this);

    const requestExceptions = (config: AxiosRequestConfig) =>
      // below line exempts request to get CMS content
      config.url?.match(Locale.English)?.length! > 0 ||
      config.url?.match(Locale.Danish)?.length! > 0 ||
      config.url === '/registerUser' ||
      config.url === '/countries' ||
      config.url === '/person/checkIfUserExists' ||
      config.url === '/registerPrivateUser' ||
      config.url === '/privateCustomers' ||
      config.url?.match(/^\/accounts\/[\w-]+\/paymentMethods\/reepay-creation-url-private$/)
        ?.length! > 0 ||
      config.url === '/paymentMethods/reepay-creation-url' ||
      config.url === '/signup-private';

    if (!this.axiosInstance) {
      this.axiosInstance = axios.create({
        baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : undefined,
        paramsSerializer(params) {
          return qs.stringify(params, { indices: false });
        }
      });

      this.axiosInstance.interceptors.request.use(
        (config: AxiosRequestConfig) =>
          new Promise((resolve, reject) => {
            if (requestExceptions(config)) {
              resolve(config);
            }
            if (this.authenticationStore.authContext) {
              this.authenticationStore.authContext.getTokenSilently().then((token: any) => {
                // eslint-disable-next-line no-param-reassign
                config.headers!.Authorization = `Bearer ${token}`;
                resolve(config);
              });
            } else {
              reject(config);
            }
          })
      );

      this.axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
          this.errorStore.handleError(error);
          return Promise.reject(error);
        }
      );

      serviceOptions.axios = this.axiosInstance;
    }
  }
}

export const storeContext = createContext(new RootStore());

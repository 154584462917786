/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import { IdentifierService } from '../swagger/api';

export enum FrontPageSearchEntity {
  Products = 'produkter',
  Usage = 'forbrug',
  Payment = 'betaling'
}

export enum ProductOverviewInformation {
  ActiveBizz = 'ActiveBizzes',
  InactiveBizz = 'InactiveBizzes',
  ActivePayByPlate = 'ActivePayByPlate',
  ActiveOneTimeANPR = 'ActiveOneTimeANPR'
}

export default class FrontPageStore extends BaseStore {
  searchEntity: string = FrontPageSearchEntity.Products;

  productsWithoutGreenDiscount: number = 0;

  numberOfActiveBizzesOnAccount: number = 0;

  numberOfInactiveBizzesOnAccount: number = 0;

  numberOfActiveLicensePlateProductsOnAccount: number = 0;

  numberOfActiveOneTimeANPRProductsOnAccount: number = 0;

  setSearchEntity = (searchEntity: FrontPageSearchEntity) => {
    this.searchEntity = searchEntity;
  };

  getProductsWithoutGreenDiscount = () => {
    IdentifierService.getNumberOfIdentifiersNotApplicableForGreenDiscount({
      accountId: this.rootStore.userStore.currentAccount?.id!
    }).then((result: number) => {
      this.productsWithoutGreenDiscount = result;
    });
  };

  getProductOverviewInformation = () => {
    IdentifierService.getNumberOfActiveBizzesOnAccount({
      accountId: this.rootStore.userStore.currentAccount?.id!
    }).then((result) => {
      this.numberOfActiveBizzesOnAccount = result;
    });
    IdentifierService.getNumberOfInactiveBizzesOnAccount({
      accountId: this.rootStore.userStore.currentAccount?.id!
    }).then((result) => {
      this.numberOfInactiveBizzesOnAccount = result;
    });
    IdentifierService.getNumberOfActiveLicensePlateProductsOnAccount({
      accountId: this.rootStore.userStore.currentAccount?.id!
    }).then((result) => {
      this.numberOfActiveLicensePlateProductsOnAccount = result;
    });
    IdentifierService.getNumberOfActiveOneTimeAnprProductsOnAccount({
      accountId: this.rootStore.userStore.currentAccount?.id!
    }).then((result) => {
      this.numberOfActiveOneTimeANPRProductsOnAccount = result;
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      searchEntity: observable,
      productsWithoutGreenDiscount: observable,
      numberOfActiveBizzesOnAccount: observable,
      numberOfInactiveBizzesOnAccount: observable,
      numberOfActiveLicensePlateProductsOnAccount: observable,
      numberOfActiveOneTimeANPRProductsOnAccount: observable,
      setSearchEntity: action,
      getProductsWithoutGreenDiscount: action
    });
  }
}

import React, { FC, useState } from 'react';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';
import { ProductInfo, ProductType } from '../../../../swagger/api';
import { ReactComponent as SmallCheckMark } from '../../../../layout/icons/white-check-in-green-circle.svg';
import { ReactComponent as BigCheckMark } from '../../../../layout/icons/white-check-in-green-circle-big.svg';
import ModalStore from '../../../../stores/ModalStore';
import ConfirmCloseAccountModal from '../../../myProfile-features/modals/ConfirmCloseAccountModal';
import PayOutstandingsBeforeAccountClose from '../../../myProfile-features/modals/PayOutstandingsBeforeAccountClose';
import CloseAccountModal from '../../../administration-features/modals/CloseAccountModal';

interface ProductWillBeClosedModalProps {
  contentStore: ContentStore;
  productInfo: ProductInfo;
  modalStore: ModalStore;
}

const ProductWillBeClosedModal: FC<ProductWillBeClosedModalProps> = (props) => {
  const { contentStore, productInfo, modalStore } = props;
  const { productItemsStore, productCardStore } = contentStore.rootStore.productStore;
  const { transactionsStore, manageUserAccessStore, userStore } = contentStore.rootStore;
  const [isLoading, setIsLoading] = useState(false);

  let header: string | undefined;
  let content: string | undefined;

  switch (productInfo.productType) {
    case ProductType.Bizz:
      header = contentStore.cmsStore.getContent('BizzWillBeClosed')?.content!;
      content = contentStore.cmsStore.getContent('BizzWillBeClosed')?.subText!;
      break;
    case ProductType.BizzCommercial:
      header = contentStore.cmsStore.getContent('BizzWillBeClosed')?.content!;
      content = contentStore.cmsStore.getContent('BizzWillBeClosed')?.subText!;
      break;
    case ProductType.OneTimeANPR:
      header = contentStore.cmsStore.getContent('PayByETicketWillBeClosed')?.content;
      content = contentStore.cmsStore.getContent('PayByETicketWillBeClosed')?.subText!;
      break;
    case ProductType.LicensePlate:
      header = contentStore.cmsStore.getContent('PayByPlateWillBeClosed')?.content;
      content = contentStore.cmsStore.getContent('PayByPlateWillBeClosed')?.subText!;
      break;
    case ProductType.TollingBox:
      header = contentStore.cmsStore.getContent('TollingBoxWillBeClosed')?.content;
      content = contentStore.cmsStore.getContent('TollingBoxWillBeClosed')?.subText!;
      break;
    default:
      break;
  }

  const primaryButtonText =
    productItemsStore.numberOfProducts === 0
      ? contentStore.cmsStore.getContent('IWantToCloseMyAccount')?.content!
      : contentStore.cmsStore.getContent('GoToBizzesAndLicensePlates')?.content!;

  const greenLinkText =
    productItemsStore.numberOfProducts === 0
      ? contentStore.cmsStore.getContent('IWantToKeepMyAccount')?.content!
      : undefined;

  const onPrimaryButtonClick = async () => {
    setIsLoading(true);
    if (productItemsStore.numberOfProducts > 0) {
      setIsLoading(false);
      modalStore.closeModal();
      productCardStore.closeModal();
    } else {
      const hasOutstandingDebt = await transactionsStore.hasOutstandingDebt();
      modalStore.closeModal();
      if (hasOutstandingDebt) {
        setIsLoading(false);
        modalStore.openModal(
          <PayOutstandingsBeforeAccountClose cmsStore={contentStore.cmsStore} />
        );
      } else {
        setIsLoading(false);
        modalStore.openModal(
          manageUserAccessStore?.isPrivateCustomer() ? (
            <ConfirmCloseAccountModal contentStore={contentStore} modalStore={modalStore} />
          ) : (
            <CloseAccountModal
              accountNumber={userStore?.currentAccount?.customerNumber!}
              closeModal={modalStore.closeModal}
            />
          )
        );
      }
    }
  };

  const closeModals = () => {
    modalStore.closeModal();
    productCardStore.closeModal();
  };

  return (
    <CustomModal
      topIconDesktop={<SmallCheckMark />}
      topIconMobile={<BigCheckMark />}
      header={header}
      contentText={content}
      closable
      primaryButtonText={primaryButtonText}
      greenLinkText={greenLinkText}
      onGreenLinkClick={closeModals}
      mobileViewSecondaryButton={greenLinkText !== undefined}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isLoading={isLoading}
    />
  );
};

export default ProductWillBeClosedModal;

/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

export default class FileHandlerStore extends BaseStore {
  uploadedFile: File | undefined = undefined;

  uploadedCo2File: File | undefined = undefined;

  base64EncodeFile = () =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(this.uploadedFile!);

      reader.onload = () => {
        resolve(this.replaceSpecialCharacter(reader.result!.toString()));
      };

      reader.onerror = (error) => reject(error);
    });

  base64EncodeCo2File = () =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(this.uploadedCo2File!);

      reader.onload = () => {
        resolve(this.replaceSpecialCharacter(reader.result!.toString()));
      };

      reader.onerror = (error) => reject(error);
    });

  // eslint-disable-next-line class-methods-use-this
  private replaceSpecialCharacter(raw: string) {
    let encoded = raw.replace(/^data:(.*,)?/, '');

    if (encoded.length % 4 > 0) {
      encoded += '='.repeat(4 - (encoded.length % 4));
    }

    return encoded;
  }

  setUploadedFile = (file: File) => {
    this.uploadedFile = file;
  };

  setUploadedCo2File = (file: File) => {
    this.uploadedCo2File = file;
  };

  clearUploadedFiles = () => {
    this.uploadedFile = undefined;
    this.uploadedCo2File = undefined;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      uploadedFile: observable,
      uploadedCo2File: observable,
      base64EncodeFile: action,
      setUploadedFile: action,
      clearUploadedFiles: action
    });
  }
}

/* eslint-disable import/no-cycle */
import { FormInstance } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import {
  AddressLight,
  Country,
  CountryService,
  IdentifierInfo,
  Order,
  OrderLine,
  OrderService
} from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import { AddressSelection } from './OrderAddressStore';
import { ProductOrder } from './OrderInfoStore';
import getMkAddressObject from '../../utils/getMkAddressObject';
import {
  isANPROrOneTimeANPR,
  isBizz,
  isOneTimeANPR,
  isTollingBox
} from '../../utils/productTypeUtilities';

export default class OrderFlowStore extends BaseStore {
  order: Order = {};

  orderAmount: number = 0;

  setOrderAmount = (amount: number) => {
    this.orderAmount = amount;
  };

  createOrderLines = () => {
    const orderLines: OrderLine[] = [];
    if (isOneTimeANPR(this.order.productType)) {
      this.setInvoicePaymentAgreementOnAllProducts();
    }
    this.rootStore.orderStore.orderInfoStore.products.map((product: ProductOrder) =>
      orderLines.push({ identifierInfo: product.info as IdentifierInfo })
    );
    this.order.orderLines = orderLines;
  };

  setInvoicePaymentAgreementOnAllProducts = () => {
    this.rootStore.orderStore.orderInfoStore.setDefaultPaymentType(
      this.rootStore.userStore.currentAccount?.paymentMethod?.type!,
      this.rootStore.userStore.currentAccount?.paymentMethod?.cardNumber ??
        this.rootStore.userStore.currentAccount?.paymentMethod?.name ??
        this.rootStore.userStore.currentAccount?.paymentMethod?.type,
      this.rootStore.userStore.currentAccount?.paymentMethod?.id!
    );
    this.rootStore.orderStore.orderInfoStore.setDefaultPaymentTypeOnProducts();
  };

  async setOrderAddress(form: FormInstance) {
    if (
      this.rootStore.orderStore.orderAddressStore.addressSelection === AddressSelection.OtherAddress
    ) {
      const mkAddressObject: AddressLight = getMkAddressObject(
        form,
        this.rootStore.addressSelectorStore
      );
      this.order.isDefaultAddress = false;
      this.order!.companyName = form.getFieldValue('companyName');
      this.order!.addressName = mkAddressObject.addressLine1;
      this.order!.zipcode = mkAddressObject.postalCode;
      this.order!.cityName = mkAddressObject.city;
      this.order!.countryId = mkAddressObject.countryId;
      const country: Country = await CountryService.getCountryById({
        countryId: mkAddressObject.countryId
      });
      this.order!.countryName = country.name;
      this.order!.addressLine2 = mkAddressObject.addressLine2;
    } else {
      this.order.isDefaultAddress = true;
    }
    this.order!.attention = form.getFieldValue('att');
    return Promise.resolve();
  }

  submitOrder = () => {
    const accountId: string = this.rootStore.userStore.currentAccount?.id!;
    this.order.accountId = accountId;
    this.order.productType = this.rootStore.orderStore.orderInfoStore.productType;
    this.createOrderLines();
    this.setOrderAmount(this.order.orderLines?.length!);
    const body: Order = this.order;
    let promise: Promise<Order>;
    if (
      this.rootStore.orderStore.orderInfoStore.fillBizzLater === 0 &&
      (isBizz(this.rootStore.orderStore.orderInfoStore.productType) ||
        isTollingBox(this.rootStore.orderStore.orderInfoStore.productType))
    ) {
      promise = OrderService.createOrder({
        accountId,
        body
      });
      promise.then((result) => {
        this.order = result;
      });
    } else if (
      this.rootStore.orderStore.orderInfoStore.fillBizzLater === 0 &&
      isANPROrOneTimeANPR(this.rootStore.orderStore.orderInfoStore.productType)
    ) {
      promise = OrderService.createLicensePlateOrder({
        accountId,
        body
      });
      promise.then((result) => {
        this.order = result;
      });
    } else {
      promise = OrderService.createFillOutLaterOrder({
        accountId,
        body
      });
      promise.then((result) => {
        this.order = result;
      });
    }
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      order: observable,
      orderAmount: observable,
      setInvoicePaymentAgreementOnAllProducts: action,
      setOrderAmount: action,
      createOrderLines: action,
      setOrderAddress: action,
      submitOrder: action
    });
  }
}

import { Tag, Row, Col } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ReactComponent as RemoveIcon } from '../../../layout/icons/remove-tag.svg';
import { OutlinedButtonText } from '../../../layout/globalFonts';
import dateFormatting from '../../../utils/dateFormatting';
import useStore from '../../../hooks/useStore';
import ContentStore from '../../../stores/ContentStore';
import { FilterObject, Filters } from '../../../Types';

interface TagsProps {
  activeFilters: Filters;
  setActiveFilters: (temporaryFilters: Filters) => void;
}

const StyledRemoveIcon = styled(RemoveIcon)`
  vertical-align: top;
  margin-left: 9px;
`;

const StyledTag = styled(Tag)`
  color: #2d2d2d;
  padding: 9px 16px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  margin: 10px 0px 0px;
  background: #fff;
`;

const StyledRemoveAll = styled(Tag)`
  color: #2d2d2d;
  background: #fff;
  padding: 9px 16px;
  margin-top: 10px;
  border: none;
`;

const StyledCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

const FilterTags: FC<TagsProps> = (props) => {
  const { activeFilters, setActiveFilters } = props;
  const contentStore: ContentStore = useStore(ContentStore);
  const { sharedDateFilterStore } = contentStore.rootStore.filterStore;

  const removeTag = (tag: string, subMenu: string) => {
    if (subMenu === 'dates') {
      sharedDateFilterStore.clearDateFilters();
    }
    const copyOfActiveFilter = JSON.parse(JSON.stringify(activeFilters));
    copyOfActiveFilter[subMenu] = copyOfActiveFilter[subMenu].filter(
      (filterObject: FilterObject) => filterObject.text !== tag
    );
    setActiveFilters(copyOfActiveFilter);
  };

  const clearFilters = () => {
    sharedDateFilterStore.clearDateFilters();
    const emptyFilters: Filters = {};
    Object.keys(activeFilters).forEach((key: string) => {
      emptyFilters[key] = [];
    });
    setActiveFilters(emptyFilters);
  };

  const numberOfActiveFilters = () => {
    let counter: number = 0;
    const keys: string[] = Object.keys(activeFilters);
    for (let i = 0; i < keys.length; i += 1) {
      counter += activeFilters[keys[i]].length;
    }
    return counter;
  };

  const getTagText = (subMenu: string, filterObject: { text: string; filter: any }) => {
    if (subMenu === 'amounts') {
      return `${filterObject.filter[0]}-${filterObject.filter[1]} kr`;
    }
    if (subMenu === 'dates') {
      const fromDate = filterObject.filter[0] ? dateFormatting(filterObject.filter[0]) : '';
      const toDate = filterObject.filter[1] ? dateFormatting(filterObject.filter[1]) : '';
      return `${fromDate} - ${toDate}`;
    }
    return filterObject.text;
  };

  return (
    <Row gutter={10}>
      {Object.keys(activeFilters).map((sub: string) =>
        activeFilters[sub].map((filterObject: { text: string; filter: any }) => (
          <Col>
            <StyledTag
              key={filterObject.text}
              closable
              onClose={() => removeTag(filterObject.text, sub)}
              closeIcon={<StyledRemoveIcon />}
            >
              <OutlinedButtonText>{getTagText(sub, filterObject)}</OutlinedButtonText>
            </StyledTag>
          </Col>
        ))
      )}
      {numberOfActiveFilters() > 2 && (
        <StyledCol>
          <StyledRemoveAll closable onClose={() => clearFilters()} closeIcon={<StyledRemoveIcon />}>
            <OutlinedButtonText>
              {contentStore.cmsStore.getContent('RemoveAll')?.content}
            </OutlinedButtonText>
          </StyledRemoveAll>
        </StyledCol>
      )}
    </Row>
  );
};
export default observer(FilterTags);

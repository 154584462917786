import { Col, Divider, Form, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import FormItem from 'antd/es/form/FormItem';
import { RichText } from '@graphcms/rich-text-react-renderer';
import {
  ContentSmallCamptonBook,
  ContentSmallGreenLink,
  GreenLinkText,
  H5CamptonBold30,
  H9CamptonBoldSemiBold19,
  SmallGrey12
} from '../../../layout/globalFonts';
import useStore from '../../../hooks/useStore';
import FullPageModal from '../../modals/FullPageModal';
import { ReactComponent as CloseIcon } from '../../../layout/icons/close-green.svg';
import AdministrationStore from '../../../stores/AdministrationStore';
import { StyledInput } from '../../../common-components/Input';
import UserAccessDropdown from './components/UserAccessDropdown';
import StickySaveRow from '../../../common-components/StickySaveRow';
import { PrimaryButton } from '../../../common-components/Buttons';
import { SubAccountsListItem, UserRole } from '../../../swagger/api';
import { openSnackbar } from '../../notifications/Snackbar';
import SearchBar from '../../search-bar/SearchBar';
import AccountAccessTable from '../edit-user-modal/components/AccountAccessTable';
import ProductAccessTable from './components/ProductAccessTable';
import useIsMobileView from '../../../hooks/useIsMobileView';
import { validateIsEmailAlreadyUsed } from '../../../utils/formValidators';
import useFetchListDataWithPagination from '../../../hooks/fetchDataHooks/useFetchListDataWithPagination';

const StyledCloseIcon = styled(CloseIcon)`
  position: fixed;
`;

const GreyText = styled(SmallGrey12)`
  margin-bottom: 8px;
`;

const HeaderSection = styled.div`
  margin-top: 82px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: #dedede;
  margin-top: 85px;
  margin-bottom: 30px;
`;

const FormSection = styled(Row)`
  padding-left: 179px;
  padding-right: 179px;
`;

const GreyContentSmallCamptonBook = styled(ContentSmallCamptonBook)`
  color: #818181;
`;

const FullPageWrapper = styled.div`
  min-height: 100vh;
`;

const CreateUserModal: FC = () => {
  const [form] = Form.useForm();
  const administrationStore: AdministrationStore = useStore(AdministrationStore);
  const { createUserStore, administrationUserStore } = administrationStore;
  const { contentStore, userStore } = administrationStore.rootStore;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);
  const isMobileView = useIsMobileView();
  const [isProductTableLoading, setIsProductTableLoading] = useState(false);

  const showTableAndStickyRow: boolean = createUserStore.selectedUserRole !== undefined;
  const showProductTable: boolean =
    createUserStore.selectedUserRole === UserRole.ProductAccess &&
    createUserStore.selectedAccountIds.length === 1;

  const onSubmit = () => {
    setIsButtonLoading(true);
    if (createUserStore.selectedUserRole === UserRole.ParentAccountAdmin) {
      createUserStore.addSelectedAccountIds([userStore.person?.defaultAccountId!]);
    }
    createUserStore
      .submitCreateUser(form.getFieldsValue())
      .then(() => {
        openSnackbar(contentStore.cmsStore.getContent('CreateUserSnackbar')?.content!);
        administrationUserStore.getUsersForAccount();
        createUserStore.closeCreateUserModal();
        createUserStore.setSelectedUserRole(undefined);
        createUserStore.setSelectedProduct(undefined);
      })
      .finally(() => setIsButtonLoading(false));
  };

  const onModalCancel = () => {
    // clean store and close modal
    createUserStore.resetSelectedAccountIds();
    createUserStore.setSelectedUserRole(undefined);
    createUserStore.setSelectedProduct(undefined);
    createUserStore.closeCreateUserModal();
  };

  let userRoleText: string | undefined;
  switch (createUserStore.selectedUserRole) {
    case UserRole.ParentAccountAdmin:
      userRoleText = 'AccountAdminRole';
      break;
    case UserRole.SubAccountAdmin:
      userRoleText = 'SubAccountAdministatorRole';
      break;
    case UserRole.ProductAccess:
      userRoleText = 'ProductAccessRole';
      break;
    default:
  }

  const isAccountTableLoading = useFetchListDataWithPagination(
    createUserStore.getAccountsWithAccessRights,
    createUserStore.accountPageNumber
  );

  useEffect(() => {
    if (showProductTable) {
      setIsProductTableLoading(true);
      createUserStore.getProductsForAccount().finally(() => setIsProductTableLoading(false));
    }
  }, [createUserStore.productsPageNumber, showProductTable]);

  const chosenAccountRow = () => (
    <>
      <StyledDivider />
      <Row gutter={24}>
        <Col xl={5} lg={5}>
          <Row style={{ marginBottom: 10 }}>
            <H9CamptonBoldSemiBold19>
              {contentStore.cmsStore.getContent('ChosenAccount')?.content}
            </H9CamptonBoldSemiBold19>
          </Row>
          <Row>
            <ContentSmallGreenLink
              style={{ marginBottom: 24 }}
              onClick={() => {
                createUserStore.resetSelectedAccountIds();
                createUserStore.setSelectedProduct(undefined);
              }}
            >
              {contentStore.cmsStore.getContent('Edit')?.content}
            </ContentSmallGreenLink>
          </Row>
        </Col>
        <Col xl={18} lg={18}>
          <Row>
            <GreyText>{contentStore.cmsStore.getContent('CustomerNr')?.content}</GreyText>
          </Row>
          <Row>
            <ContentSmallCamptonBook>
              {
                createUserStore.accountsWithAccessRights!.filter(
                  (account: SubAccountsListItem) =>
                    account.id === createUserStore.selectedAccountIds![0]
                )[0].customerNumber
              }
            </ContentSmallCamptonBook>
          </Row>
        </Col>
      </Row>
    </>
  );

  const chooseAccountText = (
    <Col style={{ marginBottom: 24 }}>
      <H9CamptonBoldSemiBold19 style={{ marginBottom: 8 }}>
        {contentStore.cmsStore.getContent('ChooseAccount')?.content}
      </H9CamptonBoldSemiBold19>
      {createUserStore.selectedUserRole === UserRole.ProductAccess && (
        <ContentSmallCamptonBook>
          {contentStore.cmsStore.getContent('ChooseAccount')?.subText}
        </ContentSmallCamptonBook>
      )}
    </Col>
  );

  const productOrAccountAccessTableComponent = (
    <>
      <StyledDivider />
      <Row gutter={24} style={{ marginBottom: '110px' }}>
        <Col xl={5} lg={24} style={{ marginBottom: 24 }}>
          <Row>
            {showProductTable ? (
              <H9CamptonBoldSemiBold19 style={{ marginBottom: 8 }}>
                {contentStore.cmsStore.getContent('ChooseProductAccess')?.content}
              </H9CamptonBoldSemiBold19>
            ) : (
              chooseAccountText
            )}
          </Row>
          {showProductTable && (
            <ContentSmallCamptonBook>
              {contentStore.cmsStore.getContent('ChooseProductAccess')?.subText}
            </ContentSmallCamptonBook>
          )}
        </Col>
        <Col xl={19} lg={20}>
          <Row style={{ marginBottom: '41px' }}>
            {showProductTable ? (
              <SearchBar
                searchVal={createUserStore.productsSearchVal}
                setSearchVal={createUserStore.setProductSearchVal}
                searchPlaceholder={contentStore.cmsStore.getContent('productSearchBar')?.content!}
                searchFunction={createUserStore.getProductsForAccount}
              />
            ) : (
              <SearchBar
                searchVal={createUserStore.accountSearchVal}
                setSearchVal={createUserStore.setAccountSearchVal}
                searchPlaceholder={
                  contentStore.cmsStore.getContent('EditUserModelSearchBar')?.content!
                }
                searchFunction={createUserStore.getAccountsWithAccessRights}
              />
            )}
          </Row>
          <Row>
            {showProductTable ? (
              <ProductAccessTable
                contentStore={contentStore}
                createUserStore={createUserStore}
                isLoading={isProductTableLoading}
              />
            ) : (
              <AccountAccessTable
                accountsWithAccessRights={createUserStore.accountsWithAccessRights!}
                hideAllSelector={createUserStore.selectedUserRole === UserRole.ProductAccess}
                contentStore={contentStore}
                selectedUserRole={createUserStore.selectedUserRole!}
                selectedAccountIds={createUserStore.selectedAccountIds}
                addSelectedAccountIds={createUserStore.addSelectedAccountIds}
                removeSelectedAccountIds={createUserStore.removeSelectedAccountIds}
                selectOrDeselectAll={createUserStore.selectOrDeselectAll}
                accountPageNumber={createUserStore.accountPageNumber}
                filteredAccountCount={createUserStore.filteredAccountCount}
                accountPageSize={createUserStore.accountPageSize}
                setCurrentAccountPage={createUserStore.setCurrentAccountPage}
                showContactPerson={false}
                isLoading={isAccountTableLoading}
              />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );

  const saveChangesButton = (
    <PrimaryButton
      htmlType="submit"
      disabled={
        ((showProductTable
          ? !createUserStore.selectedProductAccessProduct
          : createUserStore.selectedAccountIds.length === 0) ||
          isPrimaryButtonDisabled) &&
        createUserStore.selectedUserRole !== UserRole.ParentAccountAdmin
      }
      loading={isButtonLoading}
      onClick={() => onSubmit()}
    >
      {contentStore.cmsStore.getContent('SendInvite')?.content}
    </PrimaryButton>
  );

  const profileTypes = (
    <RichText
      content={contentStore.cmsStore.getContent('ProfileTypesMoreInfo')?.richContent?.raw ?? []}
      renderers={{
        p: ({ children }) => <p style={{ marginBottom: '0px', lineHeight: '19px' }}>{children}</p>,
        a: ({ children, href }) => (
          <a target="_blank" href={href} rel="noopener noreferrer">
            <GreenLinkText style={{ lineHeight: '19px' }}>{children}</GreenLinkText>
          </a>
        )
      }}
    />
  );

  return (
    <FullPageModal
      visible={createUserStore.isCreateUserModalVisible}
      footer={null}
      onCancel={onModalCancel}
      maskClosable
      closeIcon={<StyledCloseIcon />}
      transitionName="modalFade"
      isopen={createUserStore.isAnimationOpen}
      ismobileview={isMobileView}
    >
      <FullPageWrapper>
        <Row justify="center">
          <Col>
            <HeaderSection>
              <H5CamptonBold30 style={{ marginBottom: '14px' }}>
                {contentStore.cmsStore.getContent('CreateUser')?.content}
              </H5CamptonBold30>
              <ContentSmallCamptonBook>
                {contentStore.cmsStore.getContent('NewUserInfo')?.content}
              </ContentSmallCamptonBook>
              <ContentSmallCamptonBook>{profileTypes}</ContentSmallCamptonBook>
            </HeaderSection>
          </Col>
        </Row>
        <FormSection>
          <Form
            style={{ width: '100%' }}
            name="CreateSubAccount"
            form={form}
            onFinish={onSubmit}
            scrollToFirstError
            onFieldsChange={() => {
              setIsPrimaryButtonDisabled(
                !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
              );
            }}
          >
            <StyledDivider />
            <Row gutter={24}>
              <Col xl={5} style={{ marginBottom: 24 }}>
                <H9CamptonBoldSemiBold19>
                  {contentStore.cmsStore.getContent('Information')?.content}
                </H9CamptonBoldSemiBold19>
              </Col>
              <Col xl={18}>
                <Row gutter={22}>
                  <Col>
                    <Row>
                      <GreyText>{contentStore.cmsStore.getContent('FirstName')?.content}</GreyText>
                    </Row>
                    <Row>
                      <FormItem
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')
                              ?.content
                          }
                        ]}
                      >
                        <StyledInput
                          maxLength={100}
                          style={{ width: '257px' }}
                          placeholder={contentStore.cmsStore.getContent('FirstName')?.content}
                        />
                      </FormItem>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <GreyText>{contentStore.cmsStore.getContent('LastName')?.content}</GreyText>
                    </Row>
                    <Row>
                      <FormItem
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')
                              ?.content
                          }
                        ]}
                      >
                        <StyledInput
                          maxLength={100}
                          style={{ width: '278px' }}
                          placeholder={contentStore.cmsStore.getContent('LastName')?.content}
                        />
                      </FormItem>
                    </Row>
                  </Col>
                  <Col />
                </Row>

                <Row style={{ marginTop: '22px' }} gutter={22} wrap={false}>
                  <Col>
                    <Row>
                      <GreyText>{contentStore.cmsStore.getContent('Email')?.content}</GreyText>
                    </Row>
                    <Row>
                      <FormItem
                        validateTrigger={['onSubmit', 'onChange']}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')
                              ?.content
                          },
                          {
                            type: 'email',
                            message: contentStore.cmsStore.getContent('invalidEmail')!.content
                          },
                          {
                            // eslint-disable-next-line consistent-return
                            validator: async (_, value) =>
                              validateIsEmailAlreadyUsed(_, value, userStore),
                            message: contentStore.cmsStore.getContent('EmailAlreadyInUse')?.content
                          }
                        ]}
                      >
                        <StyledInput
                          maxLength={100}
                          style={{ width: '257px' }}
                          placeholder={contentStore.cmsStore.getContent('EnterEmail')?.content}
                        />
                      </FormItem>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <GreyText>{contentStore.cmsStore.getContent('UserAccess')?.content}</GreyText>
                    </Row>
                    <Row>
                      <UserAccessDropdown
                        contentStore={contentStore}
                        createUserStore={createUserStore}
                      />
                    </Row>
                  </Col>
                  <Col xl={10} lg={10} style={{ marginTop: 24 }}>
                    {userRoleText !== undefined && (
                      <GreyContentSmallCamptonBook>
                        {contentStore.cmsStore.getContent(userRoleText)?.subText}
                      </GreyContentSmallCamptonBook>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {showProductTable && chosenAccountRow()}
          </Form>
          {showTableAndStickyRow && productOrAccountAccessTableComponent}
        </FormSection>
        {showTableAndStickyRow && (
          <StickySaveRow contentStore={contentStore} saveButtonWithClick={saveChangesButton} />
        )}
      </FullPageWrapper>
    </FullPageModal>
  );
};

export default observer(CreateUserModal);

import React from 'react';
import { Select } from 'antd';
import { ProductType } from '../swagger/api';
import { ReactComponent as BizzBigIcon } from '../layout/icons/payment/bizz-big.svg';
import { ReactComponent as LicenseBigPlateIcon } from '../layout/icons/payment/license-plate-big.svg';
import { ReactComponent as OneTimeAnprBigIcon } from '../layout/icons/one-time-anpr-big.svg';
import { ReactComponent as OneTimeAnprIcon } from '../layout/icons/one-time-anpr.svg';
import { ReactComponent as LicenseSmallPlateIcon } from '../layout/icons/payment/license-plate.svg';
import { ReactComponent as BizzSmallIcon } from '../layout/icons/payment/bizz.svg';
import { ReactComponent as TollingBoxSmallIcon } from '../layout/icons/tolling-box.svg';
import { ReactComponent as TollingBoxBigIcon } from '../layout/icons/tolling-box-big.svg';
// eslint-disable-next-line import/no-cycle
import useStore from '../hooks/useStore';
import ContentStore from '../stores/ContentStore';

export const isBizz = (productType?: ProductType) =>
  productType === ProductType.Bizz || productType === ProductType.BizzCommercial;

export const isANPR = (productType?: ProductType) => productType === ProductType.LicensePlate;

export const isOneTimeANPR = (productType?: ProductType) => productType === ProductType.OneTimeANPR;

export const isANPROrOneTimeANPR = (productType?: ProductType) =>
  isANPR(productType) || isOneTimeANPR(productType);

export const isTollingBox = (productType?: ProductType) => productType === ProductType.TollingBox;

export const getLargeProductIcon = (productType: ProductType) => {
  switch (productType) {
    case ProductType.Bizz:
      return <BizzBigIcon />;
    case ProductType.BizzCommercial:
      return <BizzBigIcon />;
    case ProductType.LicensePlate:
      return <LicenseBigPlateIcon />;
    case ProductType.OneTimeANPR:
      return <OneTimeAnprBigIcon />;
    case ProductType.TollingBox:
      return <TollingBoxBigIcon />;
    default:
      return <BizzBigIcon />;
  }
};

export const getSmallProductIcon = (productType: ProductType) => {
  switch (productType) {
    case ProductType.Bizz:
      return <BizzSmallIcon />;
    case ProductType.BizzCommercial:
      return <BizzSmallIcon />;
    case ProductType.LicensePlate:
      return <LicenseSmallPlateIcon />;
    case ProductType.OneTimeANPR:
      return <OneTimeAnprIcon />;
    case ProductType.TollingBox:
      return <TollingBoxSmallIcon style={{ transform: 'translate(0, -8px)' }} />;
    default:
      return <BizzSmallIcon />;
  }
};

export const orderAndProductCardIsoCodeOptions = (productType: ProductType) => {
  const { Option } = Select;
  const contentStore: ContentStore = useStore(ContentStore);
  const mapAllowedIsoCodesToIsoCodeOptions = (allowedIsoCodes: string[]) =>
    allowedIsoCodes.map((isoCode) => (
      <Option value={isoCode} key={isoCode}>
        {isoCode}
      </Option>
    ));

  let allowedIsoCodes: string[];
  switch (productType) {
    case ProductType.Bizz: // Fall through to use same Isocodes as the next case
    case ProductType.BizzCommercial:
    case ProductType.TollingBox:
      allowedIsoCodes = contentStore.genericContentStore
        .getCountriesWithQuery({
          allowShipping: true
        })
        .map((country) => country.isoCode!);
      break;
    case ProductType.LicensePlate: // Fall through to use same Isocodes as the next case
      allowedIsoCodes = contentStore.genericContentStore
        .getCountriesWithQuery({
          allowForCommercialMinKontoAnpr: true
        })
        .map((country) => country.isoCode!);
      break;
    case ProductType.OneTimeANPR:
      allowedIsoCodes = contentStore.genericContentStore
        .getCountriesWithQuery({
          allowForeTicket: true
        })
        .map((country) => country.isoCode!);
      break;
    default:
      allowedIsoCodes = ['DK'];
  }

  const countryIsoCodeOptions = mapAllowedIsoCodesToIsoCodeOptions(allowedIsoCodes);
  return countryIsoCodeOptions;
};

import React, { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import useStore from '../hooks/useStore';
import UserStore from '../stores/UserStore';
import ProtectedSelectAccountRoute from './ProtectedRoutes/ProtectedSelectAccountRoute';
import ProtectedProductRoute from './ProtectedRoutes/ProtectedProductRoute';
import ProtectedUsagePaymentOrderRoute from './ProtectedRoutes/ProtectedUsagePaymentOrderRoute';
import ProtectedAdministrationRoute from './ProtectedRoutes/ProtectedAdministrationRoute';
import ProtectedFrontPageRoute from './ProtectedRoutes/ProtectedFrontPageRoute';
import ProtectedPrivateRoute from './ProtectedRoutes/ProtectedPrivateRoute';
import { ProductType } from '../swagger/api';
import ProtectedBizzesAndLicensePlatesRoute from './ProtectedRoutes/ProtectedBizzesAndLicensePlatesRoute';
import LoadingSpinner from '../common-components/LoadingSpinner';
import RouteUrlPaths from '../RouteUrlPaths';
import TransactionAndUsage from '../pages/TransactionAndUsage';
import ProtectedCreateCustomerRoute from './ProtectedRoutes/ProtectedCreateCustomerRoute';
import SelectTollingBoxOrderType from '../features/order-features/business-order-features/select-tolling-box-order-type/SelectTollingBoxOrderType';

const Administration = lazy(() => import('../pages/Administration'));
const ChooseDefaultAccount = lazy(() => import('../pages/ChooseDefaultAccount'));
const CustomerPage = lazy(() => import('../pages/CustomerPage'));
const FrontPage = lazy(() => import('../pages/FrontPage'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Order = lazy(() => import('../pages/Order'));
const OrderProductFlow = lazy(() => import('../pages/OrderProductFlow'));
const Payment = lazy(() => import('../pages/Payment'));
const Products = lazy(() => import('../pages/Products'));
const Transactions = lazy(() => import('../pages/Transactions'));
const MyProfile = lazy(() => import('../pages/MyProfile'));
const BizzesAndLicensePlates = lazy(() => import('../pages/BizzesAndLicensePlates'));
const PaymentCard = lazy(() => import('../pages/PaymentCardPage'));

const AppRoutes: FC = () => {
  const userStore: UserStore = useStore(UserStore);
  const { manageUserAccessStore } = userStore.rootStore;
  const isSignUpInProgress = () => userStore.person?.signUpInProgress;
  const isPrivateCustomerInDebtCollection = () =>
    manageUserAccessStore.isPrivateCustomerInDebtCollection();
  const isPrivateCustomer = () => manageUserAccessStore.isPrivateCustomer();

  const privateCustomerRoutes = (
    <>
      <Route
        path={RouteUrlPaths.PrivateProducts}
        element={
          <ProtectedBizzesAndLicensePlatesRoute manageUserAccessStore={manageUserAccessStore}>
            <BizzesAndLicensePlates />
          </ProtectedBizzesAndLicensePlatesRoute>
        }
      />
      <Route
        path={RouteUrlPaths.TransactionAndUsage}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <TransactionAndUsage />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.Order}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <Order />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.OrderLicensePlate}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <OrderProductFlow productType={ProductType.LicensePlate} />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.OrderBizz}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <OrderProductFlow productType={ProductType.Bizz} />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.PaymentCard}
        element={
          <ProtectedPrivateRoute manageUserAccessStore={manageUserAccessStore}>
            <PaymentCard />
          </ProtectedPrivateRoute>
        }
      />
      <Route
        path={RouteUrlPaths.Frontpage}
        element={
          <ProtectedFrontPageRoute userStore={userStore}>
            <FrontPage />
          </ProtectedFrontPageRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedFrontPageRoute userStore={userStore}>
            <NotFound />
          </ProtectedFrontPageRoute>
        }
      />
      <Route path={RouteUrlPaths.MyProfile} element={<MyProfile />} />
    </>
  );

  const commercialCustomerRoutes = (
    <>
      <Route
        path={RouteUrlPaths.SelectTollingBoxOrderType}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <SelectTollingBoxOrderType />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.OrderTollingBox}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <OrderProductFlow productType={ProductType.TollingBox} />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.ConvertToTollingBox}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <OrderProductFlow productType={ProductType.TollingBox} />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.SelectAccount}
        element={
          <ProtectedSelectAccountRoute userStore={userStore}>
            <ChooseDefaultAccount />
          </ProtectedSelectAccountRoute>
        }
      />
      <Route
        path={RouteUrlPaths.CommercialProducts}
        element={
          <ProtectedProductRoute manageUserAccessStore={manageUserAccessStore}>
            <Products />
          </ProtectedProductRoute>
        }
      />
      <Route
        path={RouteUrlPaths.Usage}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <Transactions />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.Payments}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <Payment />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.Order}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <Order />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.Administration}
        element={
          <ProtectedAdministrationRoute userStore={userStore}>
            <Administration />
          </ProtectedAdministrationRoute>
        }
      />
      <Route path={RouteUrlPaths.MyProfile} element={<MyProfile />} />
      <Route
        path={RouteUrlPaths.OrderEticket}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <OrderProductFlow productType={ProductType.OneTimeANPR} />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.OrderLicensePlate}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <OrderProductFlow productType={ProductType.LicensePlate} />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.OrderBizz}
        element={
          <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
            <OrderProductFlow productType={ProductType.BizzCommercial} />
          </ProtectedUsagePaymentOrderRoute>
        }
      />
      <Route
        path={RouteUrlPaths.Frontpage}
        element={
          <ProtectedFrontPageRoute userStore={userStore}>
            <FrontPage />
          </ProtectedFrontPageRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedFrontPageRoute userStore={userStore}>
            <NotFound />
          </ProtectedFrontPageRoute>
        }
      />
    </>
  );

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        {!isSignUpInProgress() && (
          <>
            {!isPrivateCustomerInDebtCollection() &&
              (isPrivateCustomer() ? privateCustomerRoutes : commercialCustomerRoutes)}
            <Route
              path={RouteUrlPaths.Frontpage}
              element={
                <ProtectedFrontPageRoute userStore={userStore}>
                  <FrontPage />
                </ProtectedFrontPageRoute>
              }
            />
            <Route
              path="*"
              element={
                <ProtectedFrontPageRoute userStore={userStore}>
                  <FrontPage />
                </ProtectedFrontPageRoute>
              }
            />
          </>
        )}
        <Route path={RouteUrlPaths.CreateCustomer} element={<CustomerPage />} />
        <Route
          path="*"
          element={
            <ProtectedCreateCustomerRoute userStore={userStore}>
              <CustomerPage />
            </ProtectedCreateCustomerRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  IdentifierInfo,
  Agreement,
  AgreementService,
  PaymentType,
  ProductType,
  OrderService,
  PaymentMethodService,
  InvoicePaymentMethodStatus,
  Service,
  VehicleDocumentationStatus,
  VehicleGreenDiscountStatus,
  ProductInfo
} from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export interface IdentifierInfoEnrichedWithProductInfo extends IdentifierInfo {
  countryIsoCode?: string;
  greenDiscountStatus?: VehicleGreenDiscountStatus;
  hasGreenDiscount?: boolean;
  latestChangeType?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  vehicleDocumentationStatue?: VehicleDocumentationStatus;
  servicesOnIdentifier?: Service[];
  paymentMethod?: { key: string; label: string; value: string };
  panNumbers?: object;
  drivableInNorway?: boolean;
  customerNumber?: string;
  customerName?: string;
  remainingTrips?: number;
  vehicleCountryId?: string;
  agreement?: { key: string; label: string; value: string };
}

export type ProductOrder = {
  key: number;
  isFilledIn: boolean;
  fileName?: string;
  co2FileName?: string;
  info: IdentifierInfoEnrichedWithProductInfo;
};

interface OrderFormDataObject {
  [key: string]: IdentifierInfoEnrichedWithProductInfo;
}

export default class OrderInfoStore extends BaseStore {
  currentOrderCount: number = 0;

  fillBizzLater: number = 0;

  defaultPaymentType: { type?: PaymentType; name?: string; id?: string } = {};

  defaultAgreement: { id?: string; name?: string } = {};

  agreements: Agreement[] = [];

  products: ProductOrder[] = [];

  productType?: ProductType = undefined;

  highestProductKeyValue: number = 0;

  savedFormFields = undefined;

  newProduct: ProductOrder = {
    key: -1,
    isFilledIn: false,
    fileName: undefined,
    info: {
      productType: undefined,
      reference: undefined,
      licensePlate: undefined,
      countryId: undefined,
      vin: undefined,
      vehicleSize: undefined,
      remainingTrips: undefined,
      fuelType: undefined,
      euronorm: undefined,
      paymentMethodId: undefined,
      agreementId: undefined,
      accountId: undefined,
      encodedDocumentPdf: undefined,
      encodedCo2DocumentPdf: undefined
    }
  };

  countriesInForm: { [index: number]: string } = {};

  areAllProductsFilled: boolean = false;

  isInformationConfirmed: boolean = false;

  productsThatAreFormTouched: number[] = [];

  shallSetDefaultAgreementAndPaymentMethod = true;

  setShallSetDefaultAgreementAndPaymentMethod = (
    shallSetDefaultAgreementAndPaymentMethod: boolean
  ) => {
    this.shallSetDefaultAgreementAndPaymentMethod = shallSetDefaultAgreementAndPaymentMethod;
  };

  setAllProductsFormTouched = () => {
    this.productsThatAreFormTouched = this.products.map((product) => product.key);
  };

  setAreAllProductsFilled = () => {
    let areFilled = !this.products.some((prod) => prod.isFilledIn === false);
    if (this.currentOrderCount === 0) areFilled = false;
    this.areAllProductsFilled = areFilled;
    if (!areFilled) this.setIsInformationConfirmed(false);
    if (areFilled) this.setAllProductsFormTouched();
  };

  setIsInformationConfirmed = (isConfirmed: boolean) => {
    this.isInformationConfirmed = isConfirmed;
  };

  setIsProductFilledIn = (key: number, isFilled: boolean) => {
    this.products.find((prod) => prod.key === key)!.isFilledIn = isFilled;
  };

  setInitialDefaultAgreement = () => {
    this.setDefaultAgreement(this.agreements[0].id, this.agreements[0].name);
    this.setDefaultAgreementOnProducts();
  };

  getActiveInvoicePaymentMethodByAgreementId = async (agreementId: string) => {
    if (this.agreements.length === 0) {
      await this.getAllAgreements();
    }
    const billingAccountId = this.agreements.find(
      (agreement: Agreement) => agreement.id === agreementId
    )?.billingAccountId!;
    const primaryPaymentAgreement =
      await PaymentMethodService.getPrimaryPaymentAgreementByBillingAccountId({
        billingAccountId
      });
    return primaryPaymentAgreement.invoicePaymentMethodStatus === InvoicePaymentMethodStatus.Active
      ? primaryPaymentAgreement
      : null;
  };

  getAllAgreements = () => {
    const customerId: string = this.rootStore.userStore.currentAccount?.id!;
    const agreementPromise = AgreementService.getAgreementsByCustomerId({ customerId });
    agreementPromise.then((keys: Agreement[]) => {
      runInAction(() => {
        this.agreements = keys;
        // If there is only one agreement the user will not see it so it shall automaticcaly be set on all orders:
        if (this.agreements.length === 1) {
          this.products.forEach((product: ProductOrder) => {
            product.info.agreementId = keys[0].id;
          });
        }
      });
    });
    return agreementPromise;
  };

  setProductType(productType: ProductType) {
    this.productType = productType;
    this.rootStore.orderStore.submitOrderStore.order.productType = productType;
  }

  // eslint-disable-next-line class-methods-use-this
  getMaxAmountOfTrips = () => OrderService.getOneTimeAnprMaxInitialRemainingTrips();

  setFileNameOnProduct = (key: number, fileName: string) => {
    const product = this.products.find((prod) => prod.key === key);
    if (product) {
      product.fileName = fileName;
    }
  };

  setCo2FileNameOnProduct = (key: number, fileName: string) => {
    const product = this.products.find((prod) => prod.key === key);
    if (product) {
      product.co2FileName = fileName;
    }
  };

  setDefaultPaymentType = (paymentType?: PaymentType, name?: string, id?: string) => {
    const defaultType: { type?: PaymentType; name?: string; id?: string } = {
      type: paymentType,
      name,
      id
    };
    this.defaultPaymentType = defaultType;
  };

  setDefaultAgreement = (id?: string, name?: string) => {
    const defaultAgreement: { id?: string; name?: string } = {
      id,
      name
    };
    this.defaultAgreement = defaultAgreement;
  };

  setDefaultPaymentTypeOnProducts = () => {
    this.products.forEach((product) => {
      product.info.paymentMethodId = this.defaultPaymentType.id;
    });
  };

  setDefaultAgreementOnProducts = () => {
    this.products.forEach((product) => {
      product.info.agreementId = this.defaultAgreement.id;
    });
  };

  setCurrentOrderCount = (currentOrderCount: number) => {
    this.currentOrderCount = currentOrderCount;
  };

  createNewProductList = () => {
    const product = JSON.parse(JSON.stringify(this.newProduct));
    this.products = Array(this.currentOrderCount).fill(product);
    this.products.forEach((item, index) => {
      item.key = index;
      item.info.accountId = this.rootStore.userStore.currentAccount?.id!;
      item.info.productType = this.productType;
    });
    this.highestProductKeyValue = this.currentOrderCount - 1;
  };

  createNewConvertBizzList = (bizzList: ProductInfo[]) => {
    const product = JSON.parse(JSON.stringify(this.newProduct));
    this.products = Array(bizzList.length).fill(product);
    this.products.forEach((item, index) => {
      item.key = index;
      item.info = bizzList[index] as IdentifierInfoEnrichedWithProductInfo;
      item.info.replacedIdentifier = bizzList[index].identifierId!;
      if (item.info.paymentMethod) {
        item.info.paymentMethod.key = bizzList[index].paymentMethod?.id!;
        item.info.paymentMethod.label =
          bizzList[index].paymentMethod?.cardNumber ?? bizzList[index].paymentMethod?.type!;
        item.info.paymentMethod.value = bizzList[index].paymentMethod?.id!;
      }
      if (item.info.agreement) {
        item.info.agreement.key = bizzList[index].agreement?.id!;
        item.info.agreement.label = bizzList[index].agreement?.name!;
        item.info.agreement.value = bizzList[index].agreement?.id!;
      }
      item.info.paymentMethodId = bizzList[index].paymentMethod?.id;
      item.info.accountId = this.rootStore.userStore.currentAccount?.id!;
      item.info.productType = this.productType;
      item.info.greenDiscountStatus = bizzList[index].greenDiscountStatus;
      item.info.countryId = bizzList[index].countryIsoCode;
    });
    this.highestProductKeyValue = this.currentOrderCount - 1;
  };

  convertProductOrderToFormData = (): OrderFormDataObject => {
    const formData: OrderFormDataObject = {};
    this.products.forEach((product, index) => {
      const identifierInfo = product.info;
      formData[index.toString()] = identifierInfo;
    });
    return formData;
  };

  addNewProduct = () => {
    this.highestProductKeyValue += 1;
    const indexOfNewProduct = this.highestProductKeyValue;
    const product = JSON.parse(JSON.stringify(this.newProduct));
    product.key = indexOfNewProduct;
    this.products.push(product);
    this.setCountryInForm(this.highestProductKeyValue, 'DK');
    this.setCurrentOrderCount(this.currentOrderCount + 1);
    this.setAreAllProductsFilled();
  };

  deleteProduct = (key: number) => {
    const index = this.products.findIndex((product) => product.key === key);
    this.products.splice(index, 1);
    this.setCurrentOrderCount(this.currentOrderCount - 1);
    this.setAreAllProductsFilled();
  };

  setFillNow = (fillNowRadioSelection: number) => {
    this.fillBizzLater = fillNowRadioSelection;
  };

  setCountryInForm = (index: number, countryId: string) => {
    this.countriesInForm[index] = countryId;
  };

  setInitialCountriesInForm = () => {
    this.products.forEach((prod) => {
      this.countriesInForm[prod.key] = 'DK';
    });
  };

  resetOrderFlow = () => {
    this.rootStore.orderStore.orderFlowStepperStore.stepCount = 0;
    this.currentOrderCount = 0;
    this.fillBizzLater = 0;
    this.defaultPaymentType = {};
    this.defaultAgreement = {};
    this.agreements = [];
    this.products = [];
    this.savedFormFields = undefined;
    this.areAllProductsFilled = false;
    this.productsThatAreFormTouched = [];
    this.rootStore.orderStore.submitOrderStore.order = {};
    this.rootStore.orderStore.selectBizzesforConversionStore.resetSelectedBizzes();
    this.rootStore.filterStore.bizzesForConversionFilterStore.resetBizzesForConversionFilterStore();
    this.setShallSetDefaultAgreementAndPaymentMethod(true);
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentOrderCount: observable,
      fillBizzLater: observable,
      defaultPaymentType: observable,
      defaultAgreement: observable,
      agreements: observable,
      isInformationConfirmed: observable,
      areAllProductsFilled: observable,
      products: observable,
      productType: observable,
      highestProductKeyValue: observable,
      countriesInForm: observable,
      savedFormFields: observable,
      shallSetDefaultAgreementAndPaymentMethod: observable,
      setShallSetDefaultAgreementAndPaymentMethod: action,
      getActiveInvoicePaymentMethodByAgreementId: action,
      setCountryInForm: action,
      setInitialCountriesInForm: action,
      setIsProductFilledIn: action,
      setIsInformationConfirmed: action,
      setAreAllProductsFilled: action,
      addNewProduct: action,
      createNewProductList: action,
      setDefaultPaymentType: action,
      setDefaultAgreement: action,
      setDefaultPaymentTypeOnProducts: action,
      setDefaultAgreementOnProducts: action,
      setCurrentOrderCount: action,
      setFillNow: action,
      getAllAgreements: action,
      setInitialDefaultAgreement: action,
      resetOrderFlow: action
    });
  }
}

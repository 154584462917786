/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';

import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

import {
  IdentifierService,
  IdentifierStatus,
  IdentifierInfo,
  Country,
  MkClosedCause,
  MkIdentifierCloseRequest,
  ServiceService,
  PartnersMkLinksTo
} from '../../swagger/api';

export default class ProductCardStore extends BaseStore {
  isModalVisible: boolean = false;

  productIdOfOpenProductCard?: string;

  isAnimationOpen: boolean = false;

  getPartnerLink = (partner: PartnersMkLinksTo) => {
    const promise = ServiceService.getPartnerUrlAsync({
      customerNumber: this.rootStore.userStore.currentAccount?.customerNumber!,
      partner,
      isPrivateCustomer: this.rootStore.manageUserAccessStore.isPrivateCustomer()
    }).then((result) => result.partnerUrl);
    return promise;
  };

  closeProduct = (identifierId: string, closedCause: MkClosedCause) => {
    const closeRequest: MkIdentifierCloseRequest = { closedCause };
    const promise = IdentifierService.closeIdentifier({
      accountId: this.rootStore.userStore.currentAccount?.id!,
      identifierId,
      body: closeRequest
    });
    return promise;
  };

  setProductIdOfOpenProductCard = (id: string) => {
    this.productIdOfOpenProductCard = id;
  };

  setIsAnimationOpen = (isOpen: boolean) => {
    this.isAnimationOpen = isOpen;
  };

  openModal = () => {
    this.setIsAnimationOpen(true);
    this.isModalVisible = true;
  };

  closeModal = async () => {
    this.setIsAnimationOpen(false);
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, 500));
    this.isModalVisible = false;
    this.productIdOfOpenProductCard = undefined;
    this.rootStore.productStore.productItemsStore.product = undefined;
  };

  saveProductcardChanges = (identifierId: string, formChanges: any) => {
    const body: IdentifierInfo = formChanges;
    body.identifierId = identifierId;
    if (body.licensePlate !== undefined)
      body.licensePlate = body.licensePlate?.toUpperCase().trim();
    body.countryId = this.rootStore.contentStore.genericContentStore.allCountries.find(
      (country: Country) => country.isoCode === formChanges.countryIsoCode
    )?.id;

    if ((body.co2Class as string) === 'None') {
      body.co2Class = undefined;
      body.resetCo2Class = true;
    }

    if (!body.maxPermissibleWeight) {
      body.maxPermissibleWeight = undefined;
      body.resetMaxPermissibleWeight = true;
    }

    if (this.rootStore.manageUserAccessStore.isPrivateCustomer()) {
      this.rootStore.productStore.productItemsStore.setIdOfProductLoadingReference(identifierId);
    }
    const promise = IdentifierService.updateIdentifierCard({
      accountId:
        this.rootStore.userStore.currentAccount?.id! ??
        this.rootStore.userStore.person?.accountIds![0]!,
      identifierId,
      body
    }).then(() => {
      this.rootStore.productStore.productItemsStore.getProducts().then(() => {
        if (this.rootStore.manageUserAccessStore.isPrivateCustomer()) {
          this.rootStore.productStore.productItemsStore.setIdOfProductLoadingReference(undefined);
        }
      });
    });
    return promise;
  };

  setBizzStatus = (identifierId: string, accountId: string, body: IdentifierStatus) => {
    const promise = IdentifierService.setBizzStatus({ identifierId, accountId, body });
    promise.then(() => {
      this.rootStore.productStore.productItemsStore.getProducts();
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      isModalVisible: observable,
      isAnimationOpen: observable,
      productIdOfOpenProductCard: observable,
      openModal: action,
      closeModal: action,
      closeProduct: action,
      saveProductcardChanges: action,
      setIsAnimationOpen: action,
      setProductIdOfOpenProductCard: action,
      getPartnerLink: action
    });
  }
}
